import { CloudOptionsEnum } from 'store/slices/sku/sku.types';
import { SourceType } from 'store/slices/sources/sources.types';
import { WorkloadEnum } from 'types';

export const workloadTypeAliases = {
    vMware: 'VMware',
    nas: 'NAS',
    windows: 'Windows',
    linux: 'Linux',
    msSql: 'MS-SQL',
    oracle: 'Oracle',
    sapHana: 'SAP HANA',
    azureVm: 'Azure VM',
    hyperV: 'Hyper-V',
    archiveFsNas: 'Archive (FS/NAS)',
    nutanixAhv: 'Nutanix AHV',
    ec2: 'EC2',
    rds: 'RDS',
};
export const workloadLabelMapType = {
    [workloadTypeAliases.vMware]: 'vMware',
    [workloadTypeAliases.nas]: 'nas',
    [workloadTypeAliases.windows]: 'windows',
    [workloadTypeAliases.linux]: 'linux',
    [workloadTypeAliases.msSql]: 'msSql',
    [workloadTypeAliases.oracle]: 'oracle',
    [workloadTypeAliases.sapHana]: 'sapHana',
    [workloadTypeAliases.azureVm]: 'azureVm',
    [workloadTypeAliases.hyperV]: 'hyperV',
    [workloadTypeAliases.archiveFsNas]: 'archiveFsNas',
    [workloadTypeAliases.nutanixAhv]: 'nutanixAhv',
    [workloadTypeAliases.ec2]: 'ec2',
    [workloadTypeAliases.rds]: 'rds',
};

export const skuPlanAliases = {
    business: 'Business',
    enterprise: 'Enterprise',
    elite: 'Elite',
};

export const skuCloudAliases = {
    govCloud: 'Gov Cloud',
    publicCloud: 'Public Cloud',
};

export const formatSources = (sources: Array<SourceType>): Array<SourceType> =>
    sources
        .map((s) => {
            let ltr = s.ltr ? 'ltr' : 'warm';
            if (s.type === WorkloadEnum.ARCHIVEFSNAS) {
                ltr = 'nA';
            }
            return { ...s, ltr };
        })
        .map((s) => ({ ...s, dtc: s.dtc ? 'yes' : 'no' }));

/**
 * Converts the bytes input into appropriate KB/MB/GB string format
 * getFormattedBytes(6357407409) will return '5.9 GB'
 * getFormattedBytes(657905499) will return '627.4 MB'
 */
export const getFormattedBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    // Byte, Kilo Byte, Mega Byte, Giga Byte, Tera Byte, Peta Byte, Exa Byte, Zetta Byte, Yotta Byte
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getFormattedBytesUnitObject = (bytes: number, decimals = 2): { formattedBytes: string; unit: string } => {
    if (bytes === 0) {
        return { formattedBytes: '0', unit: 'Bytes' };
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;

    // Byte, Kilo Byte, Mega Byte, Giga Byte, Tera Byte, Peta Byte, Exa Byte, Zetta Byte, Yotta Byte
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return { formattedBytes: `${parseFloat((bytes / k ** i).toFixed(dm))}`, unit: sizes[i] };
};

const dataSizeMappingWithDeploymentPeriod = {
    50: 15,
    100: 30,
    200: 60,
    300: 90,
    400: 120,
    500: 150,
};

export const getDeploymentPeriodBasedOnDataSize = (dataSize: string): number => {
    for (const size in dataSizeMappingWithDeploymentPeriod) {
        if (+dataSize <= +size) {
            return dataSizeMappingWithDeploymentPeriod[size];
        }
    }
    return 180;
};

export const getDailyGrowth = (annualGrowth) => {
    const annualGrowthRate = annualGrowth / 100;
    const dailyGrowth = (1 + annualGrowthRate) ** (1 / 365) - 1;
    return +(dailyGrowth * 100).toFixed(7);
};

export const getAnnualGrowth = (dailyGrowth) => {
    const dailyGrowthRate = dailyGrowth / 100;
    const annualGrowth = (1 + dailyGrowthRate) ** 365 - 1;
    return +(annualGrowth * 100).toFixed(2);
};

export const parseStringToFloat = (input: string, precision = 999): string => {
    let output = input;
    const prefix = output?.length && output[0] === '-' ? '-' : '';
    // strip leading zeros if needed
    if (output?.length > 1 && output[1] !== '.') output = output.replace(/^0+/g, '');
    // strip any non-number symbols
    output = output.replace(/[^0-9.]/g, '');
    // strip unnecessary dots and commas
    const splitString = output.split(/[.,]/);
    const integerPart = splitString.shift();
    const fractionalPart = splitString.length ? `.${splitString.join('').slice(0, precision)}` : '';
    const returnValue = `${prefix}${integerPart}${fractionalPart}`;
    return returnValue || '0';
};

export const getTimeString = (timestamp: number) =>
    new Date(timestamp).toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

export const getCurrentTimeFormatted = (timestamp: number, separator: string) => {
    const now = new Date(timestamp);

    // Get the date components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    // Get the time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    const formattedTime = `${year}${separator}${month}${separator}${day} ${hours}${separator}${minutes}${separator}${seconds}`;

    return formattedTime;
};

export const getCloudType = (phoenixCloudUrl: string) => {
    const cloudTypeMapping = {
        'phoenix.druva.com': CloudOptionsEnum.PUBLIC_CLOUD,
        'govphoenix.druva.com': CloudOptionsEnum.GOV_CLOUD,
        'fedphoenix.druva.com': CloudOptionsEnum.FED_CLOUD,
    };

    return (cloudTypeMapping[phoenixCloudUrl] ?? CloudOptionsEnum.PUBLIC_CLOUD) as CloudOptionsEnum;
};

export const CUSTOMER_DETAILS = 'Customer Details';
export const NEW_SOURCE_DATA_SHEET = 'New Data Sources';
export const EXISTING_SOURCE_DATA_SHEET = 'Existing Data Sources';
export const SUMMARY_DETAILS = 'Summary';
export const BACKUP_DETAILS = 'Backup';
export const CUSTOMER_DETAILS_KEY_LABEL_MAP = {
    customername: 'Customer Name',
    customerType: 'Customer Type',
};
export const CUSTOMER_DETAILS_LABEL_KEY_MAP = {
    [CUSTOMER_DETAILS_KEY_LABEL_MAP.customername]: 'customername',
    [CUSTOMER_DETAILS_KEY_LABEL_MAP.customerType]: 'customerType',
};

export const NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING = {
    type: 'Workload Type',
    dedupe: 'Dedupe Ratio',
    size: 'Data Size (TB)',
    annualGrowth: 'Annual Growth %',
    dailyGrowth: 'Daily Growth %',
    dailyChange: 'Daily Change %',
    deploymentPeriod: 'Deployment Period (# Days)',
    'retention.daily': 'Daily Retention',
    'retention.weekly': 'Weekly Retention',
    'retention.monthly': 'Monthly Retenion',
    'retention.yearly': 'Yearly Retention',
};

export const NEW_CUSTOMER_SOURCE_LABEL_KEY_MAPPING = {
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.type]: 'type',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.dedupe]: 'dedupe',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.size]: 'size',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.annualGrowth]: 'annualGrowth',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.dailyGrowth]: 'dailyGrowth',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.dailyChange]: 'dailyChange',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING.deploymentPeriod]: 'deploymentPeriod',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING['retention.daily']]: 'retention.daily',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING['retention.weekly']]: 'retention.weekly',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING['retention.monthly']]: 'retention.monthly',
    [NEW_CUSTOMER_SOURCE_KEY_LABEL_MAPPING['retention.yearly']]: 'retention.yearly',
};

export const replaceEmptySpace = (text) => text.replace(/\s/g, '');
