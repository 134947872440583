import React, { memo, useState } from 'react';
import { InputTitle, InputGroup } from 'components/forms';
import { useRootState } from 'store/StateProvider';
import Button from 'components/Button/Button';
import { addButton } from 'components/Button/styles';
import './Sources.scss';
import { Formik } from 'formik';
import { formatSources, getDailyGrowth } from 'utils/utils';
import { WorkloadEnum } from 'types';
import { updateDiscount } from 'store/slices/actions';
import _ from 'lodash';
import SourcesConfirmationDialog from 'components/Dialog/SourcesConfirmationDialog';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import DailyChangeConfirmationDialog from 'components/Dialog/DailyChangeConfirmationDialog';
import { SourceType } from 'store/slices/sources/sources.types';
import { workloadTypeMappingForAPI } from 'views/constants';
import ImportCSV from 'components/ImportCSV/ImportCSV';
import { sourcesValidationSchema } from './SourcesForm/SourcesForm.validation';
import useSources from './hooks/useSources';
import CustomerDetails from './CustomerDetails';
import ExistingSourcesDetails from './ExistingSourcesDetails';
import SourcesForm from './SourcesForm/SourcesForm';

type Props = {
    isEditDialog?: boolean;
};

const SourcesComponent = ({ isEditDialog }) => {
    const {
        sourcesData: {
            count,
            customerDetails: { customerType, deploymentSummary },
        },
        location: { isDell },
    } = useRootState();
    const [archiveDailyChanged, setArchiveDailyChanged] = useState(false);
    const [isOpenDailyChangeConfirmation, setIsOpenDailyChangeConfirmation] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [actualsChanged, setActualsChanged] = useState(false);
    const [archiveSelected, setArchiveSelected] = useState(false);
    const {
        dispatch,
        state: {
            sourcesData: { sources, tcoYear },
            sku: { discounts, defaultValues },
        },
        handleSubmit,
    } = useSources();

    const handleFormSubmit = (vSources) => {
        // Update discount as per selectedTcoYear
        dispatch(
            updateDiscount({
                discounts: _.range(+tcoYear).map((index) => discounts[index] ?? 0),
                defaultValues: {
                    ...defaultValues,
                    discounts: _.range(+tcoYear).map(() => 0),
                },
            }),
        );

        handleSubmit(vSources, isEditDialog);
    };
    const handleNextSave = (values: { sources: SourceType[]; tcoYear: number }) => {
        let showDialog = false;
        if (Array.isArray(deploymentSummary?.details)) {
            const serviceToActualMapping = {};
            for (const item of deploymentSummary?.details) {
                const { serviceName, annualGrowthRate, dailyChangeRate, effectiveDedupRatio } = item;
                const dailyGrowthRate = getDailyGrowth(annualGrowthRate);
                serviceToActualMapping[serviceName] = {
                    annualGrowthRate,
                    dailyGrowthRate,
                    dailyChangeRate,
                    effectiveDedupRatio,
                };
            }
            for (const source of values.sources) {
                const serviceName = workloadTypeMappingForAPI[source.type];
                if (serviceToActualMapping[serviceName]) {
                    const { annualGrowthRate, dailyGrowthRate, dailyChangeRate, effectiveDedupRatio } =
                        serviceToActualMapping[serviceName];
                    if (
                        +annualGrowthRate !== +source.annualGrowth ||
                        +dailyGrowthRate !== +source.dailyGrowth ||
                        +dailyChangeRate !== +source.dailyChange ||
                        +effectiveDedupRatio !== +source.dedupe
                    ) {
                        setActualsChanged(true);
                        showDialog = true;
                    }
                }
            }
        }

        if (values.sources.some((obj) => obj.type === WorkloadEnum.ARCHIVEFSNAS)) {
            setArchiveDailyChanged(
                values.sources.some((obj) => obj.type === WorkloadEnum.ARCHIVEFSNAS && obj.dailyChange > 0),
            );
            setArchiveSelected(true);
            showDialog = true;
        }
        if (showDialog) {
            setIsOpen(showDialog);
        } else {
            handleFormSubmit(values.sources);
        }
    };

    const { handleAddSource } = useSources();

    return (
        <Formik
            initialValues={{ sources: formatSources(sources), tcoYear }}
            onSubmit={handleNextSave}
            validationSchema={sourcesValidationSchema}
            validateOnBlur={false}
            className="qa-formik-sources"
        >
            {(formikProps) => (
                <>
                    {isEditDialog ? null : (
                        <>
                            <CustomerDetails formikProps={formikProps} />
                            <div className="mt-40">
                                <h3>Data Sources</h3>
                                {customerType === 'Existing' && <ExistingSourcesDetails />}
                                <div className="sources-hint">
                                    {customerType === 'Existing' && (
                                        <h4 className="section-heading">New Data Sources</h4>
                                    )}
                                    <span>
                                        Please add the details of the new data sources to be protected with{' '}
                                        {isDell ? 'Dell Technologies' : 'Druva'}.
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="mt-20">
                        <Button
                            className="qa-button-add-source"
                            handleClick={() => handleAddSource(formikProps.values.sources)}
                            style={addButton()}
                        >
                            Add Source
                        </Button>
                        <ImportCSV formikProps={formikProps} />
                    </div>
                    <div className="sources qa-sources-table-header-container">
                        <div className="source">
                            <InputGroup title={`Sources (${count})`} />
                            <div>
                                <InputTitle text="" />
                            </div>
                        </div>
                        <div className="details">
                            <InputGroup title="Server Details" />
                            <div className="details-sub-header">
                                <InputTitle text="Workload Type" />
                                <InputTitle text="Dedupe Ratio" />
                                <InputTitle text="Data Size (TB)" />
                            </div>
                        </div>
                        <div className="dataChange">
                            <InputGroup
                                title="Data Change"
                                tooltip={
                                    <TooltipIcon
                                        style={{ color: 'white' }}
                                        title={
                                            <div>
                                                Annual Growth (%) - Percentage at which the source data is expected to
                                                grow annually.
                                                <br />
                                                <br />
                                                Daily Growth (%) - Percentage at which the source data is expected to
                                                grow daily.
                                                <br />
                                                <br />
                                                Daily Change (%) - Percentage of daily incremental change expected in
                                                the source data.
                                                <br />
                                                <br />
                                                Deployment Period - Duration over which the source data is onboarded.
                                                {customerType === 'Existing' && (
                                                    <>
                                                        <br />
                                                        <br />
                                                        Note: If the customer has the same workload in their environment
                                                        already, the values are auto populated based on the customer
                                                        actuals.
                                                    </>
                                                )}
                                            </div>
                                        }
                                        arrow
                                        placement="top"
                                    />
                                }
                            />
                            <div className="dataChange-sub-header">
                                <InputTitle text="Annual Growth (%)" />
                                <InputTitle text="Daily Growth (%)" />
                                <InputTitle text="Daily Change (%)" />
                                <InputTitle text="Deployment Period (# Days)" />
                            </div>
                        </div>
                        <div className="storageTier">
                            <InputGroup title="Storage Tier" />
                            <div className="row">
                                <InputTitle text="Warm/LTR" />
                            </div>
                        </div>
                        <div className="retention">
                            <InputGroup title="Retention" />
                            <div className="row">
                                <InputTitle text="Dailies" />
                                <InputTitle text="Weeklies" />
                                <InputTitle text="Monthlies" />
                                <InputTitle text="Yearlies" />
                            </div>
                        </div>
                    </div>

                    <SourcesForm
                        formikProps={formikProps}
                        isEditDialog={isEditDialog}
                        setIsOpenDailyChangeConfirmation={setIsOpenDailyChangeConfirmation}
                    />
                    <div className="label-red label-xs qa-div-sources-error">
                        {Array.isArray(formikProps.errors.sources) &&
                        formikProps.errors.sources.some((error) => typeof error?.retention === 'string')
                            ? 'Number of daily recovery points should not exceed the max LTR threshold of 60'
                            : null}
                    </div>

                    <SourcesConfirmationDialog
                        isOpen={isOpen}
                        onClose={() => {
                            setIsOpen(false);
                        }}
                        onContinue={() => {
                            setIsOpen(false);
                            handleFormSubmit(formikProps.values.sources);
                        }}
                        archiveDailyChanged={archiveDailyChanged}
                        actualsChanged={actualsChanged}
                        archiveSelected={archiveSelected}
                    />
                    <DailyChangeConfirmationDialog
                        isOpen={isOpenDailyChangeConfirmation}
                        onClose={() => {
                            setIsOpenDailyChangeConfirmation(false);
                        }}
                        onContinue={() => {
                            setIsOpenDailyChangeConfirmation(false);
                        }}
                    />
                </>
            )}
        </Formik>
    );
};

SourcesComponent.defaultProps = {
    isEditDialog: false,
};

export default memo<Props>((props) => <SourcesComponent {...props} />);
