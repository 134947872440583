import React, { memo, useCallback } from 'react';
import { Toolbar } from '@material-ui/core';
import 'components/Navbar/navbar.scss';
import { useRootDispatch } from 'store/StateProvider';
import { resetSteps } from 'store/slices/actions';
import { usePdfDataContext } from 'config/context/pdfDataContext';

import { openDrawer } from 'store/slices/assumptions/assumptionsActions';
import AssumptionsDrawer from 'components/AssumptionsDrawer/AssumptionsDrawer';
import IconButton from 'components/IconButton/IconButton';
import AccountDetailsPopover from './AccountDetailsPopover';

const DellNavbar = memo(() => {
    const dispatch = useRootDispatch();
    const { changeTabIndex } = usePdfDataContext();
    const open = useCallback(() => {
        dispatch(openDrawer());
    }, [dispatch]);
    const handleHomeClick = () => {
        changeTabIndex(0);
        dispatch(resetSteps());
    };

    return (
        <nav className="navbar dellnavbar">
            <div className="navbar__logo-section">
                <Toolbar className="clickable" onClick={handleHomeClick}>
                    <img src="/dell.svg" alt="Dell Technology" />
                    <h3>APEX Backup Service</h3>
                    <h3>Credit Sizing Tool V2</h3>
                </Toolbar>
            </div>

            <div className="navbar__buttons">
                <IconButton icon="/DELL-ICON-1.svg" text="Assumptions" onClick={open} className="qa-btn-assumption" />
                <AccountDetailsPopover />
            </div>
            <AssumptionsDrawer />
        </nav>
    );
});

export default DellNavbar;
