import React from 'react';
import { FormControl } from '@material-ui/core';
import { SelectStyled, useDropdownStyles } from 'components/forms/Select/styles';
import MenuItem from '@material-ui/core/MenuItem';
import camelCase from 'lodash/camelCase';
import { useInput } from 'components/forms/hooks/useInput';

export type SelectProps = {
    clearError?: boolean;
    name: string;
    options: string[];
    disabled?: boolean;
    handleChange?: Function;
    className?: string;
    containerClassName?: string;
};

const Select = ({
    clearError,
    name,
    options,
    disabled = false,
    handleChange,
    className,
    containerClassName,
}: SelectProps): JSX.Element => {
    const { meta, field, onChange } = useInput({ name, clearError });
    const { dropdownStyle } = useDropdownStyles({});

    const handleSelectChange = (e) => {
        if (typeof handleChange === 'function') {
            handleChange(e);
        }
        return onChange(e);
    };

    return (
        <FormControl error={Boolean(meta.error && meta.touched)} variant="outlined" className={containerClassName}>
            <SelectStyled
                {...field}
                onChange={handleSelectChange}
                variant="outlined"
                MenuProps={{ classes: { paper: dropdownStyle } }}
                disabled={disabled}
                className={className}
            >
                {options.map((option: string) => (
                    <MenuItem key={option} value={camelCase(option)}>
                        {option}
                    </MenuItem>
                ))}
            </SelectStyled>
        </FormControl>
    );
};
Select.defaultProps = {
    clearError: false,
    disabled: false,
    handleChange: null,
    className: '',
    containerClassName: '',
};

export default Select;
