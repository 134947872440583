interface CoralogixWindow extends Window {
    dCoralogix?: any;
    CoralogixRum?: any;
}

declare const window: CoralogixWindow;

export const initCoralogixRum = (email: string) => {
    if (window.dCoralogix) {
        const { host } = window.location;
        const isProdCloud = host.includes('.druva.com');
        window.dCoralogix.init({
            application: 'creditSizerUI',
            deploymentId: '0',
            dev: !isProdCloud,
            user_context: {
                user_id: email,
                user_name: email.split('@')[0],
                user_email: email,
            },
            beforeSend: (event) => {
                if (event.custom_measurement_context) {
                    const data = JSON.parse(event.custom_measurement_context.name);
                    const labels = {
                        ...event.labels,
                        ...data,
                    };
                    event.labels = labels;
                }
                return event;
            },
        });
    }
};

export const sendCustomMeasurement = (obj: object) => {
    // Send the custom measurement to Coralogix
    window.CoralogixRum?.sendCustomMeasurement(JSON.stringify(obj), new Date().getTime());
};

export const corologixUtils = { sendCustomMeasurement, initCoralogixRum };
