import { createAction } from 'typesafe-actions';
import { CalculatedDataResponse } from 'types';
import { SourceType, SourcesState } from './sources.types';

export const updateSources = createAction('UPDATE_SOURCES', (sources: Array<SourceType>) => sources)<
    Array<SourceType>
>();
export const updateCount = createAction('UPDATE_COUNT', (count: number) => count)<number>();
export const addCount = createAction('ADD_COUNT')<null>();
export const subtractCount = createAction('SUBTRACT_COUNT')<null>();
export const resetCount = createAction('RESET_COUNT')<null>();
export const resetSources = createAction('RESET')<null>();
export const resetTCOYear = createAction('RESET_TCO_YEAR')<null>();
export const updateTCOYear = createAction('UPDATE_TCO_YEAR', (tcoYear: number) => tcoYear)<number>();
export const resetFirstYearMonths = createAction('RESET_FIRST_YEAR_MONTHS')<null>();
export const updateFirstYearMonths = createAction(
    'UPDATE_FIRST_YEAR_MONTHS',
    (firstYearMonths: number) => firstYearMonths,
)<number>();
export const updateSourcesResults = createAction(
    'UPDATE_SOURCES_RESULTS',
    (payload: CalculatedDataResponse) => payload,
)<CalculatedDataResponse>();
export const saveCustomerDetails = createAction(
    'SAVE_CUSTOMER_DETAILS',
    (customerDetails: Partial<SourcesState['customerDetails']>) => customerDetails,
)<any>();
export const resetCustomerDetails = createAction('RESET_CUSTOMER_DETAILS')<null>();
