import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TooltipProps } from '@material-ui/core';
import Tooltip from './Tooltip';

type Props = {
    title: string | JSX.Element;
    arrow?: boolean;
    placement: TooltipProps['placement'];
    style?: React.CSSProperties;
    fontSize?: 'small' | 'inherit' | 'large' | 'medium';
};

const TooltipIcon = ({ title, placement, arrow, style, fontSize }: Props): JSX.Element => (
    <Tooltip title={title} placement={placement} arrow={arrow}>
        <HelpOutlineIcon fontSize={fontSize} style={style} />
    </Tooltip>
);

TooltipIcon.defaultProps = {
    arrow: false,
    style: {},
    fontSize: 'medium',
};

export default TooltipIcon;
