import React, { useState } from 'react';
import TooltipIcon from 'components/Tooltip/TooltipIcon';
import useSources from 'views/Sources/hooks/useSources';
import { useRootState } from 'store/StateProvider';
import { saveCustomerDetails } from 'store/slices/actions';
import { InputBaseStyled } from 'components/forms/Input/styles';
import { Button, FormControl } from '@material-ui/core';
import CalculateCreditBalanceDialog from 'components/Dialog/CalculateCreditBalanceDialog';
import { parseStringToFloat } from 'utils/utils';

const BalanceCredit = (): JSX.Element => {
    const {
        sourcesData: {
            customerDetails: { customerType, balanceCredits, firebirdData, lastUpdateDateBalanceCredits },
        },
    } = useRootState();
    const { dispatch } = useSources();
    const [showModal, setShowModal] = useState(false);
    if (customerType === 'New') {
        return null;
    }
    const lastUpdated = lastUpdateDateBalanceCredits
        ? new Date(lastUpdateDateBalanceCredits * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
          })
        : '--';
    return (
        <>
            <div className="details-item qa-label-credit-balance-deficit">
                Credit {+balanceCredits < 0 ? 'Deficit' : 'Balance'}
            </div>
            <div className="details-item qa-calculate-credit-container">
                <FormControl variant="outlined">
                    <InputBaseStyled
                        name="balanceCredits"
                        style={{ width: '100px' }}
                        value={balanceCredits}
                        onChange={(e) => {
                            dispatch(
                                saveCustomerDetails({
                                    balanceCredits: parseStringToFloat(e.target.value),
                                    ignorePrepopulateCreditBalance:
                                        +e.target.value === 0 || Number.isNaN(+e.target.value),
                                    creditBalanceAddedUsing: 'manuallyFilled',
                                }),
                            );
                        }}
                        type="string"
                        disableUnderline
                    />
                </FormControl>
                <TooltipIcon
                    placement="right"
                    title="Enter negative value for credit deficit"
                    arrow
                    style={{ marginLeft: '10px' }}
                />
                <Button id="link-button" onClick={() => setShowModal(true)}>
                    Calculate Balance
                </Button>
                {showModal ? (
                    <CalculateCreditBalanceDialog onClose={() => setShowModal(false)} lastUpdated={lastUpdated} />
                ) : null}
            </div>
            {!!lastUpdateDateBalanceCredits && balanceCredits === firebirdData.balanceCredits && (
                <>
                    <div className="details-item" />
                    <div className="details-item last-updated-day-container">(Balance as on {lastUpdated})</div>
                </>
            )}
        </>
    );
};

export default BalanceCredit;
