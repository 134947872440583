import React, { useMemo } from 'react';
import Input from 'components/forms/Input/Input';
import 'components/AssumptionsDrawer/assumptions-drawer.scss';
import useAssumptions from 'views/Sources/hooks/useAssumptions';
import { useRootState } from 'store/StateProvider';
import { WorkloadTypesEnum } from 'types';

const DedupeTable = () => {
    const assumptions = useAssumptions();
    const {
        auth: { isDellUser: isDell },
    } = useRootState();
    const filteredAssumptionsDedupeData = useMemo(() => {
        if (!isDell) {
            return assumptions.dedupe.data;
        }
        return assumptions.dedupe.data.filter((row) => row.workloadType !== WorkloadTypesEnum.RDS);
    }, [isDell, assumptions.dedupe.data]);
    return (
        <table className={`dedup-table${isDell ? '-dell' : ''}`}>
            <thead>
                <tr>
                    <th>Data Types and Dedup Ratio</th>
                    <th> </th>
                    <th>Dedup(X)</th>
                </tr>
            </thead>
            <tbody>
                {filteredAssumptionsDedupeData.map((row) => (
                    <tr key={row.workloadType}>
                        <td>{row.dataTypesAndDedupeRatio}</td>
                        <td className="qa-label-container">{row.workloadType}</td>
                        <td className="table-cell-editable qa-value-container">
                            <Input
                                type="number"
                                name={`dedupe.${row.key}`}
                                defaultValue={assumptions.dedupe[row.key]}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default React.memo(DedupeTable);
