import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash';

import 'react-tabs/style/react-tabs.css';
import 'views/ResultsTabs/tabs.scss';

import { resetSteps, saveCustomerDetails, updateDiscount, updateTCOYear } from 'store/slices/actions';
import { useRootState, useRootDispatch } from 'store/StateProvider';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import ProgresGraphs from 'views/ProgressGraphs/ProgressGraphs';

import SelectTCOYear from 'components/SelectTCOYear/SelectTCOYear';
import PDFButton from 'components/PDFButton/PDFButton';
import { usePdfDataContext } from 'config/context/pdfDataContext';
import useGetSizingStatus from 'hooks/query/useGetSizingStatus';
import useGetResult from 'hooks/query/useGetResult';
import useDoSizing from 'hooks/query/useDoSizing';
import { SIZING_STATUS_COMPLETE, SIZING_STATUS_FAILED, workloadTypeMappingForAPI } from 'views/constants';
import { corologixUtils } from 'corologix/utils';
import SummaryTable from './SummaryTable/SummaryTable';
import Summary from './Summary/Summary';
import SummaryGraph from './Summary/BackupTable/SummaryGraph/SummaryGraph';

import SourceTable from './SourceTable/SourceTable';

const tabsList = [
    { name: 'Summary', Component: Summary },
    { name: 'Summary Table', Component: SummaryTable },
    { name: 'Sources', Component: SourceTable },
    { name: 'Storage Growth', Component: ProgresGraphs },
];

const Tabs = (): React.ReactElement => {
    const {
        sourcesData: {
            sources,
            tcoYear,
            firstYearMonths,
            customerDetails: { selectedProductCustomerId, customerType, creditBalanceAddedUsing, sizingId },
        },
        sku: { plan, cloud, discounts, defaultValues },
        auth: { email },
        assumptions: { ltr, dtc, changeRates },
    } = useRootState();

    const dispatch = useRootDispatch();
    const { tabIndex, changeTabIndex } = usePdfDataContext();

    const [fetchView, setFetchView] = useState(false);
    const [startPolling, setStartPolling] = useState(false);

    const { mutateAsync, isPending: isTriggeringSizing } = useDoSizing();
    const { data: sizingStatusResponse, isFetching: isSizingStatusFetching } = useGetSizingStatus(startPolling);
    const isResultFetching = useGetResult(fetchView);

    const payload = useMemo(
        () => ({
            considerExistingResources: customerType === 'Existing',
            loggedInAdminEmail: email,
            timestamp: new Date().valueOf(),
            product: {
                cloudType: cloud,
                discounts: discounts.map((discount) => discount / 100),
                durationInMonths: tcoYear * 12,
                ...(selectedProductCustomerId ? { productCustomerID: +selectedProductCustomerId } : {}),
                productID: 12289,
                skipMonthsInFirstYear: 12 - firstYearMonths,
                sku: plan,
                assumptions: {
                    changeRates: {
                        monthlyCap: changeRates.monthlyCap,
                        monthlyMultiplier: changeRates.monthlyMultiplier,
                        weeklyCap: changeRates.weeklyCap,
                        weeklyMultiplier: changeRates.weeklyMultiplier,
                        yearlyCap: changeRates.yearlyCap,
                        yearlyMultiplier: changeRates.yearlyMultiplier,
                    },
                    creditRates: {
                        dtc: dtc.dtc,
                        ltr: ltr.ltr,
                    },
                },
                serviceConfigurations: sources.map((source) => ({
                    serviceID: source.id,
                    serviceKey: workloadTypeMappingForAPI[source.type],
                    configuration: {
                        annualGrowth: source.annualGrowth / 100,
                        dailyChange: source.dailyChange / 100,
                        dailyGrowth: source.dailyGrowth,
                        dedupRatio: +source.dedupe,
                        deploymentPeriod: source.deploymentPeriod,
                        ltr: source.ltr,
                        resourceType: 'backupset',
                        resourceUnits: '-1',
                        totalSize: source.size,
                        retention: {
                            type: 'GGFS',
                            values: {
                                daily: source.retention.daily,
                                weekly: source.retention.weekly,
                                monthly: source.retention.monthly,
                                yearly: source.retention.yearly,
                            },
                        },
                    },
                })),
            },
        }),
        [sources, tcoYear, plan, cloud, discounts, changeRates, dtc, ltr],
    );

    // should retrigger sizing if user edit sources, discount, tcoyear, edition or cloudtype
    useEffect(() => {
        setFetchView(false);
        setStartPolling(false);
        dispatch(saveCustomerDetails({ sizingId: null }));
        mutateAsync(payload).then((data: any) => {
            if (data.success) {
                dispatch(saveCustomerDetails({ sizingId: data.result.sizingID }));
                setStartPolling(true);
            }
        });
    }, [payload]);

    useEffect(
        () => () => {
            dispatch(resetSteps());
        },
        [],
    );

    // if sizing is complete then fetch View API,
    // if sizing is still processing then refetch the status
    // if sizing fails then show error
    useEffect(() => {
        if (sizingStatusResponse?.success && startPolling) {
            const { sizingStatus } = sizingStatusResponse.result as any;
            if (sizingStatus === SIZING_STATUS_COMPLETE) {
                setFetchView(true);
                setStartPolling(false);
            } else if (sizingStatus === SIZING_STATUS_FAILED) {
                toast.error(`Could not make API request. Status: ${sizingStatus}`, {
                    position: 'bottom-right',
                });
                setStartPolling(false);
            }
            corologixUtils.sendCustomMeasurement({
                customerType,
                creditBalance: creditBalanceAddedUsing,
                exportFormat: null,
                priceInformation: null,
                sizingId,
                firstYearMonthsConsidered: customerType === 'Existing' ? firstYearMonths : null,
                sizingStatus,
            });
        } else {
            setFetchView(false);
            setStartPolling(false);
        }
    }, [sizingStatusResponse]);

    const handleChange = (value: number) => {
        // Update discount as per selected value
        dispatch(
            updateDiscount({
                discounts: _.range(value).map((index) => discounts[index] ?? 0),
                defaultValues: {
                    ...defaultValues,
                    discounts: _.range(value).map(() => 0),
                },
            }),
        );
        // Update selected tcoYear
        dispatch(updateTCOYear(value));
    };
    return (
        <TabsComponent className="tabs" selectedIndex={tabIndex} onSelect={changeTabIndex}>
            <div
                style={{ visibility: 'visible', position: 'absolute', top: 0, left: '-9999px' }}
                className="summary-graph-for-pdf"
            >
                <SummaryGraph />
            </div>
            <SelectTCOYear style={{ paddingLeft: '8px' }} tcoYear={tcoYear} handleValueChange={handleChange} />
            <PDFButton className="tabs__button-export qa-btn-result-export" />
            <TabList className="tabs__tabs-container">
                {tabsList.map((tab) => (
                    <Tab key={tab.name} className="tabs__tab" selectedClassName="tabs__tab-active">
                        {tab.name}
                    </Tab>
                ))}
            </TabList>

            {tabsList.map(({ name, Component }) => (
                <TabPanel key={name}>
                    {isTriggeringSizing || startPolling || isResultFetching || isSizingStatusFetching ? (
                        <div className="tabs__loader-wrapper">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Component />
                    )}
                </TabPanel>
            ))}
        </TabsComponent>
    );
};

export default Tabs;
