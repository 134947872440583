import { useRootState } from 'store/StateProvider';
import { AssumptionsState } from 'store/slices/assumptions/assumptions.types';

const workloadToServiceMapping = {
    EC2: 'EC2', // TODO
    RDS: 'RDS',
    'Azure VM': 'Azure VM', // TODO
    VMware: 'VMware',
    'Hyper-V': 'Hyper-V',
    'Nutanix AHV': 'Nutanix AHV',
    NAS: 'NAS',
    Windows: 'Windows', // TODO
    Linux: 'Linux', // TODO
    'Archive (FS/NAS)': 'Archive (FS/NAS)', // TODO
    'MS-SQL': 'MS-SQL',
    Oracle: 'Oracle', // TODO
    'SAP HANA': 'SAP HANA',
};

const useAssumptions = () => {
    const {
        assumptions,
        sourcesData: {
            customerDetails: { deploymentSummary },
        },
    } = useRootState();

    const serviceToDedupeMapping = {};
    if (Array.isArray(deploymentSummary?.details)) {
        for (const item of deploymentSummary?.details) {
            const { serviceName, effectiveDedupRatio } = item;

            serviceToDedupeMapping[serviceName] = effectiveDedupRatio;
        }
    }

    const newDedupe = { ...assumptions.dedupe };
    const data = assumptions.dedupe.data.map((item) => {
        const { workloadType, key, dedupe } = item;
        const service = workloadToServiceMapping[workloadType];
        const newDedupeRatio = serviceToDedupeMapping[service] ?? dedupe;
        newDedupe[key] = newDedupeRatio;
        return { ...item, dedupe: newDedupeRatio };
    });
    newDedupe.data = data;

    const newAssumption: AssumptionsState = {
        ...assumptions,
        dedupe: newDedupe,
    };
    return newAssumption;
};

export default useAssumptions;
